import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/pengaturan/email',
        component: () => import('@/layout/SettingsEmailLayout.vue'),
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
